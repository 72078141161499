@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/sweetalert/dev/sweetalert";

$brand-primary:     #00b2ad;
$brand-success:     #16B350;
$brand-info:        #359eb6;
$brand-warning:   	#f1e98b;
$brand-danger:      #F7441E;

html {
  position: relative;
  min-height: 100%;
}

body{
    font-family: 'Raleway', sans-serif;
}

.bold{
    font-weight: bold;
}

.underline{
	border-bottom: solid $brand-primary 2px;
}
// apply to the ul to use the nice glyphicons as
// style type
.check{
    list-style-type: none;
    li:before{
        font-family: 'Glyphicons Halflings';
        content:"\e067";
        color: $brand-primary;
    }
}

.collapsed{
	border:solid black 1px;
	p, span{
		color:black;
	}
}

h1{
	font-family: 'Bad Script';
	font-size: 2.887em;
}

h2{
	color: darken($brand-warning, 20%);
	font-family: 'Bad Script';
	font-size: 2.566em;
}

h3{
	color: $brand-info;
	font-size: 2.281em;
}

h4{
	font-size: 2.027em;
}

p{
	font-size: 1.266em;
	border-left: solid $brand-warning 0.5em;
	padding-left: 0.5em;
}

// home page tiles
// 
.tiles{
	margin: 0;
	padding: 0;
	img{
		max-width:100%;
		margin: 0;
		padding:0
	}
}

